<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/property_early_payment_discounts', route: '/admin/property/property_early_payment_discounts', name: 'property_early_payment_discount' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'PropertyEarlyPaymentDiscount'
}
</script>
